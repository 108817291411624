import { isNil } from 'lodash';

export enum Preference {
  MilestoneNotify = 'milestone_notify',
}

export const PreferenceDefaults = {
  [Preference.MilestoneNotify]: 'false',
};

export type UserPreference = {
  preference: Preference;
  userId: string;
  value: string;
};

export function preferenceFromString(value: string): Preference {
  const preference =
    Preference[
      Object.keys(Preference)[
        Object.values(Preference)
          .map((e) => e.toString())
          .indexOf(value)
      ] as keyof typeof Preference
    ];

  if (isNil(preference)) {
    throw new Error(`Invalid preference: ${value}`);
  }

  return preference;
}
