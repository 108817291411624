import { getLogger } from '@summer-health/shared/next-logger';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

interface UseMobileDetectionResponse {
  isMobileApp: boolean;
}

const logger = getLogger('useMobileAppDetection');

export const useMobileAppDetection = (): UseMobileDetectionResponse => {
  const [isMobileApp, setIsMobileApp] = useState(true);
  const router = useRouter();

  useEffect(
    () => {
      const isAndroidWebView =
        window.navigator.userAgent.includes('x-android-webview');
      const isIOSWebView = window.navigator.userAgent.includes('x-ios-webview');

      const isApp =
        isIOSWebView ||
        isAndroidWebView ||
        !!router.query.forceIsApp ||
        router.pathname.startsWith('/app');

      setIsMobileApp(isApp);

      logger.info(
        `App detection: isMobileApp=${isApp}, isAndroidWebView=${isAndroidWebView}, isIOSWebView=${isIOSWebView}, user-agent=${window.navigator.userAgent}`,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isMobileApp,
  };
};
