import { useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0/client';
import { MobileAppContext } from '../context/mobile-app-provider';

export const LogoutTimer = ({
  timeoutInMinutes,
}: {
  timeoutInMinutes: number;
}): JSX.Element | null => {
  const router = useRouter();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const { user, isLoading } = useUser();
  const { isMobileApp } = useContext(MobileAppContext);

  const resetLogoutTimer = (): void => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(
      () => {
        if (user && user?.registeredDate) {
          if (isMobileApp) {
            router.push(`/api/auth/logout?returnTo=/app-login`);
            return;
          }
          router.push(`/api/auth/logout?returnTo=/home`);
        }
      },
      timeoutInMinutes * 60 * 1000,
    );
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isLoading && user && user?.registeredDate) {
      window.addEventListener('scroll', resetLogoutTimer);
      window.addEventListener('mousedown', resetLogoutTimer);
      window.addEventListener('mousemove', resetLogoutTimer);
      window.addEventListener('keydown', resetLogoutTimer);
      window.addEventListener('touchstart', resetLogoutTimer);

      resetLogoutTimer();

      return (): void => {
        window.removeEventListener('scroll', resetLogoutTimer);
        window.removeEventListener('mousedown', resetLogoutTimer);
        window.removeEventListener('mousemove', resetLogoutTimer);
        window.removeEventListener('keydown', resetLogoutTimer);
        window.removeEventListener('touchstart', resetLogoutTimer);

        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default LogoutTimer;
