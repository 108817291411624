/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import pino, { Logger } from 'pino';
import { Options } from 'pino-http';
import { serializeError } from 'serialize-error';
import { transmit } from './transmit';

const SeverityLookup = new Map(
  Object.entries({
    default: 'DEFAULT',
    trace: 'DEBUG',
    verbose: 'DEBUG',
    debug: 'DEBUG',
    info: 'INFO',
    warn: 'WARNING',
    error: 'ERROR',
    fatal: 'CRITICAL',
  }),
);

const mapError = (o: any): any => {
  try {
    Object.keys(o).forEach((key) => {
      if (o[key] instanceof Error) {
        o[key] = serializeError(o[key]);
      }
    });
    return o;
  } catch {
    return o;
  }
};

const pinoConfig: Options = {
  level: 'debug',
  browser: {
    asObject: true,
    transmit: transmit({ level: 'debug', throttole: 500 }),
    serialize: true,
    // Datadog logger needs console.log call explicitly
    write: {
      fatal: (o) => {
        console.error(mapError(o));
      },
      error: (o) => {
        console.error(mapError(o));
      },
      warn: (o) => {
        console.warn(mapError(o));
      },
      info: (o) => {
        console.log(mapError(o));
      },
      debug: (o) => {
        console.log(mapError(o));
      },
      trace: (o) => {
        console.log(mapError(o));
      },
    },
  },
  messageKey: 'message',
  formatters: {
    level(label: string, number: number): { severity: string; level: number } {
      return { severity: SeverityLookup.get(label) || 'INFO', level: number };
    },
    log(object) {
      // format nextjs SSR log
      return mapError(object);
    },
  },
};

const sharedLogger = pino(pinoConfig);

export const getLogger = (name: string): Logger =>
  sharedLogger.child({ context: name });
