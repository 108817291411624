import { ReactElement, createContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0/client';
import { UserStatus } from '@summer-health/types/user';
import { useMobileAppDetection } from './use-mobile-app-detection';

interface MobileAppProviderProps {
  children: React.ReactNode;
}

interface MobileAppContext {
  isMobileApp: boolean;
}
export const MobileAppContext = createContext<MobileAppContext>({
  isMobileApp: false,
});

export function MobileAppProvider({
  children,
}: MobileAppProviderProps): ReactElement<MobileAppContext> {
  const { user } = useUser();
  const router = useRouter();

  const { isMobileApp } = useMobileAppDetection();

  useEffect(() => {
    if (isMobileApp && user?.status === UserStatus.New) {
      router.push('/api/auth/logout?returnTo=/app/unregistered');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isMobileApp]);

  return (
    <MobileAppContext.Provider
      value={{
        isMobileApp,
      }}
    >
      {children}
    </MobileAppContext.Provider>
  );
}
