/* eslint-disable no-console */
import { throttle } from 'lodash';
import { Level, LogEvent } from 'pino';
import { serializeError } from 'serialize-error';

export type TransmitOptions = {
  level: string;
  throttole: number;
};

let buffer: LogEvent[] = [];

const sendData = async (): Promise<void> => {
  try {
    if (buffer.length === 0) {
      return;
    }
    const blob = new Blob([JSON.stringify(buffer)]);
    buffer = [];
    if (!window.navigator.sendBeacon('/api/logSink', blob)) {
      console.error('sendBeacon failed');
    }
  } catch (e) {
    console.error(e);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapError = (o: any): any => {
  try {
    Object.keys(o).forEach((key) => {
      o[key] = serializeError(o[key]);
    });
    return o;
  } catch {
    return o;
  }
};

export const transmit = (
  options: TransmitOptions,
): {
  level?: Level | string;
  send: (level: Level, logEvent: LogEvent) => void;
} => {
  let sendFunc = throttle(sendData, options.throttole, {
    trailing: true,
    leading: false,
  });

  if (typeof window !== 'undefined') {
    window.addEventListener('unload', () => {
      const oldSendFunc = sendFunc;
      sendFunc = throttle(sendData, 0, {
        trailing: true,
        leading: false,
      });
      if (typeof oldSendFunc.flush === 'function') {
        oldSendFunc.flush();
      }
    });
  }

  return {
    level: options.level,
    send: (_level: Level, logEvent: LogEvent): void => {
      if (typeof window === 'undefined') {
        return;
      }
      try {
        logEvent.messages = mapError(logEvent.messages);
        buffer.push(logEvent);
        sendFunc();
      } catch (e) {
        console.error('Failed to transmit logs');
      }
    },
  };
};
