import { Dispatch, SetStateAction } from 'react';
import { NextRouter } from 'next/router';
import { datadogLogs } from '@datadog/browser-logs';
import { getLogger } from '@summer-health/shared/next-logger';
import { Flags } from '../types/flags';
import { AppConfig } from '../types/app-config';

const logger = getLogger('asyncConfigSetup');

const configureSegment = (router: NextRouter, data: AppConfig): void => {
  if (data?.segmentWriteKey && window.analytics?.load) {
    // The load function is only available once
    // after it's called, it becomes undefined
    window.analytics.load(data.segmentWriteKey);
    window.analytics.page();
    router.events.on('routeChangeComplete', () => {
      window.analytics.page();
    });
  }
};

const datadogInit = (data: AppConfig): void => {
  if (data?.datadogClientToken) {
    datadogLogs.init({
      clientToken: data.datadogClientToken,
      site: 'us3.datadoghq.com',
      env: data.datadogEnv,
      service: 'care',
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      version: process.env.NEXT_PUBLIC_BUILD_ID,
    });
    // eslint-disable-next-line no-console
    console.log(
      `initialized with version: ${process.env.NEXT_PUBLIC_BUILD_ID}`,
    );
  } else {
    // eslint-disable-next-line no-console
    console.log('No datadog configuration provided.');
  }
};

export const fetchConfig = async (): Promise<AppConfig | null> => {
  try {
    const response = await fetch(`/api/config`);
    const json = await response.json();
    return json.data;
  } catch (error) {
    logger.error('Failed to fetch config:', error);
    return null;
  }
};

export const asyncConfig = async (
  router: NextRouter,
  setFlags: Dispatch<SetStateAction<Flags>>,
  setConfig: Dispatch<SetStateAction<AppConfig>>,
): Promise<void> => {
  const config = await fetchConfig();

  if (!config) {
    logger.error('Failed to fetch config');
    return;
  }

  setFlags(config.featureFlags);
  configureSegment(router, config);
  datadogInit(config);
  setConfig(config);
};
